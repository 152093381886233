<template>
	<div class="avaNews">
		<div class="body">
			<div class="left">
				<div class="back">
					<img @click="router.go(-1)" src="../assets/ava/back.png" alt="" />
					{{ $t('blog.AMANews') }}
				</div>
				<div class="title">
					{{ blogList[type].title }}
				</div>
				<div class="date">
					{{ blogList[type].date }}
				</div>
				<div class="subtitle" ref="aaa">
					{{ blogList[type].nav[0].title }}
				</div>
				<ul class="content">
					<li v-for="(item, index) in blogList[type].nav[0].ul" :key="index">
						{{ item }}
					</li>
				</ul>
				<img :src="locale == 'zh' ? blogList[type].cnImg : blogList[type].img" alt="" class="logo1" />
				<div class="content">
					{{ blogList[type].body }}
				</div>
				<div class="subtitle" ref="bbb">
					{{ blogList[type].nav[1].title }}
				</div>
				<div class="content" v-for="(item, index) in blogList[type].nav[1].ul" :key="index">
					{{ item }}
				</div>
				<div class="subtitle" ref="ccc">
					{{ blogList[type].nav[2].title }}
				</div>
				<div class="content" v-for="(item, index) in blogList[type].nav[2].ul" :key="index">
					{{ item }}
				</div>
			</div>
			<div class="navright">
				<div class="us_box">
					<div class="title">Follow Us</div>
					<div class="icon_box">
						<a href="https://x.com/M3DAO_global" target="_blank">
							<img src="@/assets/footer/icon1.png" alt=""
						/></a>
						<a href="https://t.me/M3DAO" target="_blank">
							<img src="@/assets/footer/icon2.png" alt=""
						/></a>
						<a href="https://discord.gg/marsversem3" target="_blank">
							<img src="@/assets/footer/icon3.png" alt=""
						/></a>
						<a href="https://www.youtube.com/@MarsVerseM3" target="_blank">
							<img src="@/assets/footer/icon4.png" alt=""
						/></a>
						<a href="https://medium.com/m3-dao" target="_blank">
							<img src="@/assets/footer/icon5.png" alt=""
						/></a>
					</div>
				</div>
				<div class="navright_box">
					<div
						v-for="(item, index) in blogList[type].nav"
						:key="index"
						class="item"
						:class="{ active: active == item.ref ? true : false }"
						@click="scrollToAnchor(item.ref)"
					>
						{{ item.title }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const { t, tm, locale } = useI18n();
const aaa = ref(null);
const bbb = ref(null);
const ccc = ref(null);
const active = ref('aaa');
const scrollToAnchor = (ref) => {
	active.value = ref;
	if (ref == 'aaa') {
		aaa.value.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
	} else if (ref == 'bbb') {
		ccc.value.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
	} else if (ref == 'ccc') {
		ccc.value.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
	}
};
const blogList = ref([
	{
		img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmbTbuRT3EXCVF4Fga8cY78mDV2L37KaVvvGVL7vw68rVA",
		cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmbDszcfgUyVrUHQKjYLwjQi2Mmy4xpMNm65WbMyskvWRu",
		date: '2024/7/11',
		title: t('blog.item1.title'),
		topic: t('blog.item1.topic'),
		body: t('blog.item1.body'),
		nav: [
			{
				title: t('blog.item1.one.title'),
				ul: tm('blog.item1.one.ul'),
				ref: 'aaa',
			},
			{
				title: t('blog.item1.two.title'),
				ul: tm('blog.item1.two.ul'),
				ref: 'bbb',
			},
			{
				title: t('blog.item1.three.title'),
				ul: tm('blog.item1.three.ul'),
				ref: 'ccc',
			},
		],
	},
	{
		img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmTvTrVmsgij42xFkuB7dcNfWSWuhgyM1NBYcERBxHkLAe",
		cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmVMa7AAtv2e621X59ftngsnMkZGeAEcAtYxaQ2LLC4jCD",
		date: '2024/7/13',
		title: t('blog.item2.title'),
		topic: t('blog.item2.topic'),
		body: t('blog.item2.body'),
		nav: [
			{
				title: t('blog.item2.one.title'),
				ul: tm('blog.item2.one.ul'),
				ref: 'aaa',
			},
			{
				title: t('blog.item2.two.title'),
				ul: tm('blog.item2.two.ul'),
				ref: 'bbb',
			},
			{
				title: t('blog.item2.three.title'),
				ul: tm('blog.item2.three.ul'),
				ref: 'ccc',
			},
		],
	},
	{
		img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmbbiyVbR9FhmdFwk98xpVqaTvkb6556PGCyZd8nSFvSXb",
		cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmWK9KVVWhCCsxHTqxvBjLLefuE4huumcei9e14YkK8SqM",
		date: '2024/8/5',
		title: t('blog.item3.title'),
		topic: t('blog.item3.topic'),
		body: t('blog.item3.body'),
		nav: [
			{
				title: t('blog.item3.one.title'),
				ul: tm('blog.item3.one.ul'),
				ref: 'aaa',
			},
			{
				title: t('blog.item3.two.title'),
				ul: tm('blog.item3.two.ul'),
				ref: 'bbb',
			},
			{
				title: t('blog.item3.three.title'),
				ul: tm('blog.item3.three.ul'),
				ref: 'ccc',
			},
		],
	},
	{
		img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmYCGh5NUHe4oECnFg28fZuc99pFb2so2iMUYcyuLsc4N2",
		cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmcW5MVhPbdgw44VwRHwx9VJJVXJAfkd5CXdx61hBPAFgs",
		date: '2024/7/10',
		title: t('blog.item4.title'),
		topic: t('blog.item4.topic'),
		body: t('blog.item4.body'),
		nav: [
			{
				title: t('blog.item4.one.title'),
				ul: tm('blog.item4.one.ul'),
				ref: 'aaa',
			},
			{
				title: t('blog.item4.two.title'),
				ul: tm('blog.item4.two.ul'),
				ref: 'bbb',
			},
			{
				title: t('blog.item4.three.title'),
				ul: tm('blog.item4.three.ul'),
				ref: 'ccc',
			},
		],
	},
	{
		img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmT28fApG9BSK3wXzoYjsUqGeassEsid1EFjjKvwCUoGsJ",
		cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmWHjvUCbCuGxyjWe3eXkTzjpysiaxrghgY9ToG32sUuFP",
		date: '2024/7/12',
		title: t('blog.item5.title'),
		topic: t('blog.item5.topic'),
		body: t('blog.item5.body'),
		nav: [
			{
				title: t('blog.item5.one.title'),
				ul: tm('blog.item5.one.ul'),
				ref: 'aaa',
			},
			{
				title: t('blog.item5.two.title'),
				ul: tm('blog.item5.two.ul'),
				ref: 'bbb',
			},
			{
				title: t('blog.item5.three.title'),
				ul: tm('blog.item5.three.ul'),
				ref: 'ccc',
			},
		],
	},

	{
		img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmVqdaBhZ6Hi6cKpo1BHZoZ64XMzzpTR6eKCvR7VwYjJA1",
		cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmTV56unCyJqSzYXMMVWNPDxTB49rqok99vpbU5gYpjwx1",
		date: '2024/7/19',
		title: t('blog.item6.title'),
		topic: t('blog.item6.topic'),
		body: t('blog.item6.body'),
		nav: [
			{
				title: t('blog.item6.one.title'),
				ul: tm('blog.item6.one.ul'),
				ref: 'aaa',
			},
			{
				title: t('blog.item6.two.title'),
				ul: tm('blog.item6.two.ul'),
				ref: 'bbb',
			},
			{
				title: t('blog.item6.three.title'),
				ul: tm('blog.item6.three.ul'),
				ref: 'ccc',
			},
		],
	},
	{
		img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmSqxWmPaCLodE69Ueb7P9MESvioFYXc3tkAiRYiEr9B75",
		cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmfAX4qkrkJKQR4o7ZMZFD4MUjss8yFHbdbC93kZ5gtgRq",
		date: '2024/7/26',
		title: t('blog.item7.title'),
		topic: t('blog.item7.topic'),
		body: t('blog.item7.body'),
		nav: [
			{
				title: t('blog.item7.one.title'),
				ul: tm('blog.item7.one.ul'),
				ref: 'aaa',
			},
			{
				title: t('blog.item7.two.title'),
				ul: tm('blog.item7.two.ul'),
				ref: 'bbb',
			},
			{
				title: t('blog.item7.three.title'),
				ul: tm('blog.item7.three.ul'),
				ref: 'ccc',
			},
		],
	},
	{
		img: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmSF9v4DMFTrnPjUZAqcbcLu85UHsSRpr4RF9G3grQNJyn",
		cnImg: "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmYotzzLRjkNHG9AtRo52XzvqcNkFNyMF1msnmeGD4VF5G",
		date: '2024/8/3',
		title: t('blog.item8.title'),
		topic: t('blog.item8.topic'),
		body: t('blog.item8.body'),
		nav: [
			{
				title: t('blog.item8.one.title'),
				ul: tm('blog.item8.one.ul'),
				ref: 'aaa',
			},
			{
				title: t('blog.item8.two.title'),
				ul: tm('blog.item8.two.ul'),
				ref: 'bbb',
			},
			{
				title: t('blog.item8.three.title'),
				ul: tm('blog.item8.three.ul'),
				ref: 'ccc',
			},
		],
	},
	{
		img: '',
		date: '',
		title: '',
		topic: '',
	},
]);
const type = useRoute().query.type;
console.log(locale.value);
</script>
<style scoped lang="scss">
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.avaNews {
		width: 100%;
		box-sizing: border-box;
		background: rgba(0, 0, 0, 1);
		color: #ffffff;
		padding: 140px 240px 100px 240px;

		.body {
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-top: 20px;
			.left {
				width: 1024px;

				.back {
					width: 100%;
					font-size: 60px;
					font-weight: 700;
					display: flex;
					align-items: center;
					img {
						width: 52px;
						margin-right: 26px;
					}
				}

				.title {
					width: 1066px;
					margin-top: 60px;
					font-weight: 700;
					font-size: 32px;
				}

				.date {
					margin-top: 20px;
					font-weight: 400;
					font-size: 22px;
				}
				.subtitle {
					font-weight: 700;
					font-size: 32px;
					margin-bottom: 12px;
					color: rgba(31, 120, 211, 1);
				}
				.content {
					font-weight: 400;
					font-size: 18px;
					margin-bottom: 30px;
					width: 1024px;
					color: rgba(255, 255, 255, 0.7);
				}
				.logo1 {
					width: 1024px;
					margin: 28px 0;
				}
				.logo2 {
					width: 422px;
					height: 290px;
					margin-bottom: 138px;
				}
			}
			.navright {
				box-sizing: border-box;
				width: 280px;
				height: auto;
				.us_box {
					display: flex;
					flex-direction: column;
					.title {
						font-size: 20px;
						font-weight: 700;
						margin-bottom: 20px;
					}
					.icon_box {
						display: flex;
						margin-bottom: 60px;
						justify-content: space-between;
						a {
							img {
								width: 40px;
								height: 40px;
								// margin-right: 15px;
								border-radius: 50%;
							}
						}

						a:nth-child(5) {
							img {
								margin-right: 0;
							}
						}
					}
				}
				.navright_box {
					position: sticky;
					top: 180px;
					max-height: 240px;
					border-left: 1px solid rgba(50, 50, 50, 1);
					.active {
						border-left: 2px solid rgba(27, 114, 203, 1);
						background: #1e1e1e;
						color: rgba(245, 245, 245, 1) !important;
					}
					.item {
						width: 100%;
						height: 80px;
						padding: 27.5px 20px;
						color: rgba(80, 80, 80, 1);
						font-size: 20px;
						font-weight: 900;
						overflow: hidden; //超出的文本隐藏
						text-overflow: ellipsis; //溢出用省略号显示
						white-space: nowrap; //溢出不换行
					}
					.item:hover {
						border-left: 2px solid rgba(27, 114, 203, 1);
						background: #1e1e1e;
						color: rgba(245, 245, 245, 1);
					}
				}
			}
		}
	}
}
</style>
